import React from "react"
import styled from "styled-components"
import { Form } from "formik"

export const ContactForm = styled(Form)`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-top: 2rem;
`
