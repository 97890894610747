import React, { useState } from "react"
import styled from "styled-components"
import { useField } from "formik"

const Label = styled.label`
  /* Label css goes here */
  display: none;
  font-family: "Lato Light", sans-serif;
  font-size: 15px;
  line-height: 30px;
  letter-spacing: 0.5px;
`

const Input = styled.input`
  /* Formatting */
  background: transparent;
  border: none;
  border-bottom: ${props =>
    props.error ? "1px solid red" : "1px solid black"};
  box-sizing: border-box;
  /* padding: 0 0.5em; */
  margin: 0 1ch 0 0;
  /* width: 100%; */
  min-width: ${props => props.placeholder && `${props.placeholder.length}ch`};
  width: ${props =>
    props.width ? `${props.width}ch` : `${props.placeholder.length}ch`};
  max-width: 100%;
  /* Typography */
  font-family: var(--paragraph);
  font-size: 20px;
  line-height: 28px;
  text-align: center;
`

const TextArea = styled.textarea`
  /* Formatting */
  background: #ffffff;
  border: ${props => (props.error ? "1px solid red" : "1px solid #979797")};
  box-sizing: border-box;
  padding: 0 0.5em;
  width: 100%;
  /* Typography */
  font-family: var(--paragraph);
`

const Checkbox = styled.input`
  background: #ffffff;
  border: ${props => (props.error ? "1px solid red" : "1px solid #bbbbbb")};
  box-sizing: border-box;
  margin-right: 8px;
`

const Error = styled.div`
  /* Typography*/
  color: red;
  font-family: "Lato Light", sans-serif;
  font-size: 9px;
  line-height: 18px;
`

export const InputGroup = styled.div`
  min-height: 50px;
  margin-bottom: 10px;
  width: 100%;
`

export const Submit = styled.button`
  background: #606469;
  border: none;
  box-sizing: border-box;
  padding: 9px 0;
  width: 100%;
  color: #ffffff;
  cursor: pointer;
  font-family: "Lato Regular", sans-serif;
  font-size: 15px;
  letter-spacing: 0.5px;
  text-align: center;
  text-transform: uppercase;
  :hover {
    background: #333333;
  }
`

export const TextInput = ({ label, ...props }) => {
  const [width, setWidth] = useState(null)
  const [field, meta] = useField(props)

  return (
    <>
      <Label htmlFor={props.id || props.name}>{label}</Label>
      <Input
        {...field}
        {...props}
        onChange={e => {
          // do stuff here
          const chars = e.target.value.length
          setWidth(chars)
          field.onChange(e)
        }}
        width={width}
        error={meta.error}
      />
      {/* {meta.touched && meta.error ? <Error>{meta.error}</Error> : null} */}
    </>
  )
}

export const TextAreaInput = ({ label, ...props }) => {
  const [field, meta] = useField(props)

  return (
    <>
      <Label htmlFor={props.id || props.name}>{label}</Label>
      <TextArea {...field} {...props} error={meta.error} />
      {meta.touched && meta.error ? (
        <Error className="error">{meta.error}</Error>
      ) : null}
    </>
  )
}

export const CheckboxInput = ({ label, ...props }) => {
  const [field, meta] = useField(props)

  return (
    <>
      <span style={{ display: "flex", alignItems: "center" }}>
        <Checkbox {...field} {...props} error={meta.error} />
        <Label htmlFor={props.id || props.name} style={{ display: "block" }}>
          {label}
        </Label>
      </span>
      {meta.touched && meta.error ? (
        <Error className="error">{meta.error}</Error>
      ) : null}
    </>
  )
}
