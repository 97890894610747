import React, { useEffect } from "react"
import { useInView } from "react-intersection-observer"
import styled from "styled-components"

import { Formik, Field } from "formik"
import * as Yup from "yup"
import { ContactForm } from "../Forms/Contact"
import { TextInput } from "../Forms/Inputs"

const ProjectWrapper = styled.div`
  display: none;

  @media screen and (min-width: 800px) {
    display: block;
    min-height: 100vh;
    padding-top: calc(60px + 28px);
  }

  .introduction {
    color: var(--navy);
    font-family: var(--paragraph);
    margin-bottom: 14px;

    @media screen and (min-width: 800px) {
      font-size: 20px;
      line-height: 28px;
    }
  }

  .contact-form {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    margin-top: 2rem;
    .form-text {
    }

    .form-inline-text {
      font-family: var(--paragraph);
      font-size: 20px;
      line-height: 28px;
      margin: 0 0 14px;
      word-wrap: break-word;
    }

    .form-label-hidden {
      display: none;
    }

    .form-inline-input {
      background: transparent;
      border: none;
      border-bottom: 2px solid var(--navy);
      font-family: var(--paragraph);
      height: 24.5px;
      margin: 0 0 14px;
      resize: horizontal;
      text-align: center;
    }

    .form-inline-input:active {
      width: auto;
    }

    .checkbox-table {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr;
      margin: 2rem 0;
      width: 100%;

      .input-group {
        display: flex;
        align-items: center;
        margin-bottom: 14px;

        .form-checkbox-label {
          font-family: "Galano Grotesque Medium", sans-serif;
          font-size: 16px;
          line-height: 26px;
          margin: 0 0.5rem;
          text-transform: uppercase;
        }
      }
    }
    .submit-wrapper {
      margin: 2rem 0;
      width: 100%;

      .special {
        background: transparent;
        border: none;
        font-family: var(--heading);
        font-size: 40px;
        line-height: 40px;
        padding: 0;
      }
    }
  }
`

const CO01Projects = ({ setBackground, setNavigation }) => {
  const THRESHOLD = [0, 0.5]
  const [ref, inView] = useInView({
    threshold: THRESHOLD,
    triggerOnce: true,
  })

  useEffect(() => {
    if (inView === true) {
      setBackground("var(--light-bg-blue)")
      setNavigation(1)
    }
  })

  return (
    <ProjectWrapper id="projects">
      <div ref={ref}></div>
      <p className="introduction">
        We want to take a moment to properly meet you.
      </p>
      <p className="introduction">Please tell us about yourself:</p>
      <Formik
        initialValues={{
          companyName: "",
          industry: "",
          interestedIn: {
            research: false,
            brandPositioning: false,
            brandDesign: false,
            campaignStrategy: false,
            storeDesign: false,
            webDesign: false,
          },
          phone: "",
          email: "",
        }}
        validationSchema={Yup.object({
          companyName: Yup.string().required("Required"),
          industry: Yup.string().required("Required"),
          phone: Yup.string()
            .matches(
              "^((?+?[0-9]*)?)?[0-9_- ()]*$",
              "Invalid Phone Number, allowed characters: 0-9, +, -, (),"
            )
            .required("Required"),
          email: Yup.string()
            .email("Invalid Email Address")
            .required("Required"),
        })}
        onSubmit={(values, actions) => {
          console.log(values)
        }}
      >
        {formik => (
          <ContactForm
            name="contact-projects"
            netlify-honeypot="bot-field"
            data-netlify={true}
          >
            <Field type="hidden" name="bot-field" />
            <p
              className="form-inline-text"
              style={{
                fontFamily: "var(--paragraph)",
                fontSize: "20px",
                lineHeight: "40px",
              }}
            >
              <TextInput
                label="Company Name"
                name="companyName"
                type="text"
                placeholder="Your company name"
              />
              is looking to redefine the{" "}
              <span>
                <TextInput
                  label="Industry"
                  name="industry"
                  type="text"
                  placeholder="industry"
                />
              </span>
              industry. I'd like to hear about collaborating with The Build for:
            </p>
          </ContactForm>
        )}
      </Formik>

      <form
        name="contact-projects"
        method="POST"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        className="contact-form"
        action="/success"
      >
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value="contact-projects" />
        <label className="form-label-hidden" htmlFor="company-name">
          Company Name:
        </label>
        <input
          type="text"
          name="company-name"
          id="company-name"
          placeholder="Your company name"
          className="form-inline-input"
          size="18"
        />
        <p className="form-inline-text"> is looking to redefine the</p>
        <label className="form-label-hidden" htmlFor="industry">
          Industry:
        </label>
        <input
          type="text"
          name="industry"
          id="industry"
          placeholder="industry"
          className="form-inline-input"
          size="15"
        />
        <p className="form-inline-text">
          industry. I'd like to hear about collaborating with The Build for:
        </p>
        <div className="checkbox-table">
          <div className="input-group">
            <input
              type="checkbox"
              name="research"
              value="Research"
              className="form-checkbox-input"
            />
            <label className="form-checkbox-label" htmlFor="research">
              Research
            </label>
          </div>
          <div className="input-group">
            <input
              type="checkbox"
              name="brand-positioning"
              value="Brand Positioning"
              className="form-checkbox-input"
            />
            <label className="form-checkbox-label" htmlFor="brand-positioning">
              Brand Positioning
            </label>
          </div>
          <div className="input-group">
            <input
              type="checkbox"
              name="brand-design"
              value="Brand Design"
              className="form-checkbox-input"
            />
            <label className="form-checkbox-label" htmlFor="brand-design">
              Brand Design
            </label>
          </div>
          <div className="input-group">
            <input
              type="checkbox"
              name="campaign"
              value="Campaign Strategy & Design"
              className="form-checkbox-input"
            />
            <label className="form-checkbox-label" htmlFor="campaign">
              Campaign Strategy & Design
            </label>
          </div>
          <div className="input-group">
            <input
              type="checkbox"
              name="flagship"
              value="Flagship Store Design"
              className="form-checkbox-input"
            />
            <label className="form-checkbox-label" htmlFor="flagship">
              Flagship Store Design
            </label>
          </div>
          <div className="input-group">
            <input
              type="checkbox"
              name="web"
              value="Website Design"
              className="form-checkbox-input"
            />
            <label className="form-checkbox-label" htmlFor="web">
              Website Design
            </label>
          </div>
        </div>

        <p className="form-inline-text">The best way to reach me at is at</p>
        <label className="form-label-hidden" htmlFor="phone">
          Phone Number:
        </label>
        <input
          type="tel"
          name="phone"
          id="phone"
          placeholder="phone"
          className="form-inline-input"
        />
        <p className="form-inline-text">and</p>
        <label className="form-label-hidden" htmlFor="email">
          Email:
        </label>
        <input
          type="email"
          name="email"
          id="email"
          placeholder="email"
          className="form-inline-input"
          style={{
            width: "270px",
          }}
        />
        <p className="form-inline-text">.</p>

        <div className="submit-wrapper">
          <input type="submit" value="Submit" className="special" />
        </div>
      </form>
    </ProjectWrapper>
  )
}

export default CO01Projects
